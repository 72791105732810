import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    access_token: null,
    refresh_token: null,
    verify_token: null,
  },
  reducers: {
    setCredentials: (state, action) => {
      state.user = action.payload.user;
      state.access_token = action.payload.access_token;
      state.refresh_token = action.payload.refresh_token;
    },
    setSignUp: (state, action) => {
      state.user = action.payload.user;
      state.verify_token = action.payload.verify_token;
    },
    logOut: (state) => {
      state.user = null;
      state.access_token = null;
      state.refresh_token = null;
      state.verify_token = null;
    },
  },
});

export const { setCredentials, setSignUp, logOut } = authSlice.actions;

export default authSlice.reducer;

import { Popover, Input, Avatar } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function HeaderSearchInput() {
  const [value, setValue] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  // Function to extract query parameter from URL
  const getQueryValue = () => {
    const params = new URLSearchParams(location.search);
    return params.get("query") || "";
  };

  // Sync the input value with the query string on component mount
  useEffect(() => {
    setValue(getQueryValue());
  }, [location.search, setValue]);

  const handleSearch = () => {
    navigate(`/search?query=${value}`);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <Popover position="bottom-end" withArrow shadow="md">
      <Popover.Target>
        <div>
          <i className="bi bi-search text-xl text-submain font-paragraphFont cursor-pointer font-bold"></i>
        </div>
      </Popover.Target>
      <Popover.Dropdown className="px-2 flex justify-between items-center gap-2 w-75 mt-2.5 ml-2">
        <Input
          value={value}
          onChange={(event) => setValue(event.currentTarget.value)}
          onKeyDown={handleKeyDown}
          placeholder="Search.."
          rightSectionPointerEvents="all"
          mt="md"
          className="w-100 mb-3"
          rightSection={
            <i
              className="bi bi-x-lg text-submain text-base font-semibold font-paragarphFont cursor-pointer"
              aria-label="Clear input"
              style={{ display: value ? undefined : "none" }}
              onClick={() => setValue("")}
            ></i>
          }
          leftSection={
            <i className="bi bi-search text-base font-paragarphFont text-submain font-semibold"></i>
          }
        />
        <Avatar
          radius="xl"
          className="cursor-pointer bg-main w-1/6"
          onClick={handleSearch}
        >
          <i className="bi bi-search text-white text-base font-semibold font-paragraphFont"></i>
        </Avatar>
      </Popover.Dropdown>
    </Popover>
  );
}

import React, { useState, useEffect, Suspense, lazy } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import LoaderLazy from "../Loader/LoaderLazy";
import { useAuth } from "../Context/authContext";
import { useDisclosure } from "@mantine/hooks";
import AddEducationForm from "../Pages/Education/EducationForm/AddEducationForm";

const VerificationModal = lazy(() =>
  import("../Shared/Modals/VerificationModal")
);
const Layout = lazy(() => import("../Home/Layout"));
const UserType = lazy(() => import("../SignUp & SignIn/UserType"));
const SignIn = lazy(() => import("../SignUp & SignIn/SignIn/SignIn"));
const MainArticle = lazy(() => import("../Pages/All Articles/MainArticle"));
const ForgetPasswordEmail = lazy(() =>
  import("../SignUp & SignIn/Forget Password/ForgetPasswordEmail")
);
const ForgetPassword = lazy(() =>
  import("../SignUp & SignIn/Forget Password/ForgetPassword")
);
const SignupAccounts = lazy(() =>
  import("../SignUp & SignIn/SignUp/SignUpHCP")
);
const SignUpMedicalCenter = lazy(() =>
  import("../SignUp & SignIn/SignUp/SignUpMedicalCenter")
);
const SignUpMedicalCompany = lazy(() =>
  import("../SignUp & SignIn/SignUp/SignUpMedicalCompany")
);
const VerificationForm = lazy(() =>
  import("../SignUp & SignIn/Forget Password/VerificationForm")
);
const HealthCareProfssional = lazy(() =>
  import("../Pages/Accounts/HealthCareProfssional")
);
const MedicalCenter = lazy(() => import("../Pages/Accounts/MedicalCenter"));
const MedicalCompany = lazy(() => import("../Pages/Accounts/MedicalCompany"));
const ArticleDetails = lazy(() =>
  import("../Pages/All Articles/Single Article Details/ArticleDetails")
);

// temp route
const ArticleForm = lazy(() =>
  import("../Pages/All Articles/Add Article/NewFormArticle")
);
// temp route

const AllGroups = lazy(() => import("../Pages/Groups/AllGroups"));
const SearchResult = lazy(() => import("../Pages/Search/SearchResult"));
const Products = lazy(() => import("../Pages/Products/Products"));
const MainEducation = lazy(() =>
  import("../Pages/Education/Educationcomponents/MainEducation")
);
const Jobs = lazy(() => import("../Pages/Jobs/Jobs"));
const HCP_Profile = lazy(() => import("../Pages/Profiles/HCP/HCP_Profile"));
const HCC_Profile = lazy(() => import("../Pages/Profiles/HCC/HCC_Profile"));
const MC_Profile = lazy(() => import("../Pages/Profiles/MC/MC_Profile"));
const JobResult = lazy(() => import("../Pages/Jobs/JobResult"));
const ConfirmCode = lazy(() =>
  import("../SignUp & SignIn/Forget Password/ConfirmCode")
);
const SingleNews = lazy(() => import("../Pages/Profiles/HCC/SingleNews"));
const SingleJob = lazy(() => import("../Pages/Jobs/SingleJob"));
const SingleProduct = lazy(() =>
  import("../Pages/Products/SingleProduct/SingleProduct")
);
const SingleCourse = lazy(() =>
  import("../Pages/Education/Single Course/SingleCourse")
);
const Instructor = lazy(() => import("../Instructor/Instructor"));
const EventMainPage = lazy(() => import("../Pages/Events/EventMainPage"));
const LiveEvent = lazy(() => import("../Pages/LiveEvents/LiveEvent"));
const NotificationCenter = lazy(() =>
  import("../Chat/Notifications/NotificationCenter")
);
const FollowingAndConnections = lazy(() =>
  import("../Shared/SinglePages/FollowingAndConnections")
);

const Messages = lazy(() => import("../Chat/Messages/Messages"));

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
export default function Routers() {
  const { user, authState } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const isAuthenticated = !!authState?.access_token;

  // const ProtectedRoute = ({ children }) => {
  //   return authState.access_token ? children : <Navigate to="/sign-in" />;
  // };
  useEffect(() => {
    setIsLoading(false);
  }, [authState]);

  const ProtectedRoute = ({ element }) => {
    if (isLoading) {
      return <LoaderLazy />;
    }
    return isAuthenticated ? element : <Navigate to="/sign-in" />;
  };

  const ProtectedVerify = ({ children }) => {
    const [opened, { open, close }] = useDisclosure(false);
    useEffect(() => {
      if (user && user?.is_verified === false) {
        open();
      } else {
        close();
      }
    }, [user?.is_verified, open]);

    return (
      <>
        {user && !user?.is_verified && (
          <>
            <VerificationModal opened={opened} close={close} />
          </>
        )}
        {children}
      </>
    );
  };
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Navigate to="/home" />} />
        <Route
          path="/home"
          element={
            // <ProtectedRoute>
            <Suspense fallback={<LoaderLazy />}>
              {/* <ProtectedVerify> */}
              <Layout />
              {/* </ProtectedVerify> */}
            </Suspense>
            // </ProtectedRoute>
          }
        />
        <Route
          path="/sign-up"
          element={
            <Suspense fallback={<LoaderLazy />}>
              <UserType />
            </Suspense>
          }
        />

        <Route
          path="/sign-in"
          element={
            <Suspense fallback={<LoaderLazy />}>
              <SignIn />
            </Suspense>
          }
        />
        <Route
          path="/articles"
          element={
            <Suspense fallback={<LoaderLazy />}>
              <MainArticle />
            </Suspense>
          }
        />
        <Route
          path="/article-details/:id"
          element={
            <Suspense fallback={<LoaderLazy />}>
              <ProtectedRoute element={<ArticleDetails />} />
            </Suspense>
          }
        />

        <Route
          path="/article-form/:id?"
          element={
            <Suspense fallback={<LoaderLazy />}>
              <ProtectedRoute element={<ArticleForm />} />
            </Suspense>
          }
        />

        <Route
          path="/groups"
          element={
            <Suspense fallback={<LoaderLazy />}>
              <AllGroups />
            </Suspense>
          }
        />
        <Route
          path="/forget-password"
          element={
            <Suspense fallback={<LoaderLazy />}>
              <ForgetPasswordEmail />
            </Suspense>
          }
        />
        <Route
          path="/reset-password"
          element={
            <Suspense fallback={<LoaderLazy />}>
              <ForgetPassword />
            </Suspense>
          }
        />
        <Route
          path="/signup-profile"
          element={
            <Suspense fallback={<LoaderLazy />}>
              <SignupAccounts />
            </Suspense>
          }
        />
        <Route
          path="/medical-center-account"
          element={
            <Suspense fallback={<LoaderLazy />}>
              <SignUpMedicalCenter />
            </Suspense>
          }
        />
        <Route
          path="/medical-company-account"
          element={
            <Suspense fallback={<LoaderLazy />}>
              <SignUpMedicalCompany />
            </Suspense>
          }
        />
        <Route
          path="/verfication-account"
          element={
            <Suspense fallback={<LoaderLazy />}>
              <VerificationForm />
            </Suspense>
          }
        />
        <Route
          path="/confirm-code"
          element={
            <Suspense fallback={<LoaderLazy />}>
              <ConfirmCode />
            </Suspense>
          }
        />
        <Route
          path="/healthcare-professional"
          element={
            <Suspense fallback={<LoaderLazy />}>
              <HealthCareProfssional />
            </Suspense>
          }
        />
        <Route
          path="/medical-center"
          element={
            <Suspense fallback={<LoaderLazy />}>
              <MedicalCenter />
            </Suspense>
          }
        />
        <Route
          path="/medical-company"
          element={
            <Suspense fallback={<LoaderLazy />}>
              <MedicalCompany />
            </Suspense>
          }
        />
        <Route
          path="/search"
          element={
            <Suspense fallback={<LoaderLazy />}>
              <SearchResult />
            </Suspense>
          }
        />
        <Route
          path="/products"
          element={
            <Suspense fallback={<LoaderLazy />}>
              <Products />
            </Suspense>
          }
        />
        <Route
          path="/product"
          element={
            <Suspense fallback={<LoaderLazy />}>
              <SingleProduct />
            </Suspense>
          }
        />
        <Route
          path="/education"
          element={
            <Suspense fallback={<LoaderLazy />}>
              <MainEducation />
            </Suspense>
          }
        />
        <Route
          path="/course/:id"
          element={
            <Suspense fallback={<LoaderLazy />}>
              <ProtectedRoute element={<SingleCourse />} />
            </Suspense>
          }
        />
        <Route
          path="/instructor-profile"
          element={
            <Suspense fallback={<LoaderLazy />}>
              <Instructor />
            </Suspense>
          }
        />

        <Route
          path="/education-form/:id?"
          element={
            <Suspense fallback={<LoaderLazy />}>
              <ProtectedRoute element={<AddEducationForm />} />
            </Suspense>
          }
        />
        <Route
          path="/jobs"
          element={
            <Suspense fallback={<LoaderLazy />}>
              <Jobs />
            </Suspense>
          }
        />
        <Route
          path="/job-search-result"
          element={
            <Suspense fallback={<LoaderLazy />}>
              <JobResult />
            </Suspense>
          }
        />
        <Route
          path="/job"
          element={
            <Suspense fallback={<LoaderLazy />}>
              <SingleJob />
            </Suspense>
          }
        />
        <Route
          path="/hcp-profile"
          element={
            <Suspense fallback={<LoaderLazy />}>
              <ProtectedRoute element={<HCP_Profile />} />
            </Suspense>
          }
        />
        <Route
          path="/hcc-profile"
          element={
            <Suspense fallback={<LoaderLazy />}>
              <HCC_Profile />
            </Suspense>
          }
        />
        <Route
          path="/mc-profile"
          element={
            <Suspense fallback={<LoaderLazy />}>
              <MC_Profile />
            </Suspense>
          }
        />
        <Route
          path="/new"
          element={
            <Suspense fallback={<LoaderLazy />}>
              <SingleNews />
            </Suspense>
          }
        />
        <Route
          path="/events"
          element={
            <Suspense fallback={<LoaderLazy />}>
              <EventMainPage />
            </Suspense>
          }
        />
        <Route
          path="/live-event"
          element={
            <Suspense fallback={<LoaderLazy />}>
              <ProtectedRoute element={<LiveEvent />} />
            </Suspense>
          }
        />
        <Route
          path="/notifications"
          element={
            <Suspense fallback={<LoaderLazy />}>
              <NotificationCenter />
            </Suspense>
          }
        />
        <Route
          path="/messages"
          element={
            <Suspense fallback={<LoaderLazy />}>
              <Messages />
            </Suspense>
          }
        />
        <Route
          path="/friends"
          element={
            <Suspense fallback={<LoaderLazy />}>
              <FollowingAndConnections />
            </Suspense>
          }
        />
        <Route
          path="/friends/:tabValue"
          element={
            <Suspense fallback={<LoaderLazy />}>
              <FollowingAndConnections />
            </Suspense>
          }
        />
      </Routes>
    </>
  );
}

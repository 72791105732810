import React from "react";
import { Link } from "react-router-dom";
import { Drawer } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useAuth } from "../Context/authContext";
import MultiLevelMenu from "./MultiLevelMenu";
import SideMenuIcons from "./SideMenuIcons";

import Logo from "../assets/images/logo.svg";
import Menu from "../assets/images/Menu.svg";
export default function SideMenu() {
  const [opened, { open, close }] = useDisclosure(false);
  const { user, signOut } = useAuth();

  const logout = async (e) => {
    e.preventDefault();
    signOut();
  };
  return (
    <>
      <Drawer
        opened={opened}
        onClose={close}
        title={<img src={Logo} alt="Logo" className="h-8" />}
        overlayProps={{ backgroundOpacity: 0.5, blur: 4 }}
      >
        <div
          className="flex items-start justify-between flex-col"
          style={{ height: "85vh" }}
        >
          {user ? (
            <>
              <div className="w-100 flex items-center flex-col justify-start gap-2">
                <SideMenuIcons />

                <div className="flex items-start justify-between space-y-5 flex-col w-100">
                  <MultiLevelMenu />
                  <Link
                    to="/articles"
                    className="text-submain font-paragraphFont font-semibold no-underline text-md hover:text-hoverColor "
                  >
                    Articles
                  </Link>
                  <Link
                    to="/education"
                    className="text-submain font-paragraphFont font-semibold no-underline text-md hover:text-hoverColor "
                  >
                    Education
                  </Link>
                  <Link
                    to="/events"
                    className="text-submain font-paragraphFont font-semibold no-underline text-md hover:text-hoverColor "
                  >
                    Events
                  </Link>
                  <Link
                    to="/jobs"
                    className="text-submain font-paragraphFont font-semibold no-underline text-md hover:text-hoverColor"
                  >
                    Jobs
                  </Link>
                  <Link
                    to="/"
                    className="text-main bg-submain p-2 rounded-3 font-paragraphFont font-semibold no-underline text-sm hover:text-white "
                  >
                    Business Solutions
                  </Link>
                </div>
              </div>

              <div>
                <Link
                  className="text-deepred text-base font-paragraphFont no-underline font-semibold"
                  onClick={logout}
                >
                  Logout
                </Link>
              </div>
            </>
          ) : (
            <>
              <div className="w-100">
                <div className="flex items-center justify-center space-x-4 mb-4">
                  <Link
                    to="/sign-in"
                    className="text-submain font-paragraphFont font-semibold no-underline text-md hover:text-hoverColor "
                  >
                    Sign In
                  </Link>
                  <Link
                    to="/sign-up"
                    className="bg-main text-white font-paragraphFont font-semibold no-underline px-3 py-2 rounded-3xl hover:bg-hoverColor"
                  >
                    Sign Up
                  </Link>
                </div>

                <div className="flex items-start justify-between space-y-5 flex-col">
                  <Link
                    to="/articles"
                    className="text-submain font-paragraphFont font-semibold no-underline text-md hover:text-hoverColor "
                  >
                    Articles
                  </Link>
                  <Link
                    to="/education"
                    className="text-submain font-paragraphFont font-semibold no-underline text-md hover:text-hoverColor "
                  >
                    Education
                  </Link>
                  <Link
                    to="/events"
                    className="text-submain font-paragraphFont font-semibold no-underline text-md hover:text-hoverColor "
                  >
                    Events
                  </Link>
                  <Link
                    to="/jobs"
                    className="text-submain font-paragraphFont font-semibold no-underline text-md hover:text-hoverColor"
                  >
                    Jobs
                  </Link>
                  <Link
                    to="/"
                    className="text-main bg-submain p-2 rounded-3 font-paragraphFont font-semibold no-underline text-sm hover:text-white "
                  >
                    Business Solutions
                  </Link>
                </div>
              </div>
            </>
          )}
        </div>
      </Drawer>
      <img
        src={Menu}
        className="w-6 h-6 md:block lg:hidden cursor-pointer"
        alt="Menu"
        onClick={open}
      />
    </>
  );
}

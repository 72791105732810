import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "../Auth/ConfigApi";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    Login: builder.mutation({
      query: (params) => ({
        method: "POST",
        url: `/auth/login`,
        body: params,
      }),
    }),
    LogOut: builder.mutation({
      query: () => ({
        method: "POST",
        url: `/auth/logout`,
      }),
    }),

    signUp: builder.mutation({
      query: (params) => ({
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        url: `/auth/register`,
        body: params,
      }),
    }),
    socialSignUpCreate: builder.mutation({
      query: (params) => ({
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        url: `/auth/social-login/create`,
        body: params,
      }),
    }),
    socialSignUp: builder.mutation({
      query: (params) => ({
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "GET",
        url: `/auth/social-login`,
        params,
      }),
    }),
    forgetPassword: builder.mutation({
      query: (params) => ({
        method: "POST",
        url: `/password/forget`,
        body: params,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useLogOutMutation,
  useSignUpMutation,
  useSocialSignUpCreateMutation,
  useForgetPasswordMutation,
  useSocialSignUpMutation,
} = authApi;

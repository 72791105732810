import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";

// import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./Context/authContext";
import store from "./store";
import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import "@mantine/notifications/styles.css";
import { GoogleOAuthProvider } from "@react-oauth/google";


//To close Inspect//
// document.oncontextmenu = function() { return false; }; // Disable right-click
// window.onkeydown = function(e) {
//     if (e.key === 'F12' || (e.ctrlKey && (e.key === 'U' || e.key === 'I'))) {
//         e.preventDefault();
//     }
// };

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId="24989077405-j3jhfrevehl8hsq03fc5cdj56lnj329p.apps.googleusercontent.com">
      <Provider store={store}>
        <AuthProvider>
          <MantineProvider>
            <Notifications position="top-right" />
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </MantineProvider>
        </AuthProvider>
      </Provider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);

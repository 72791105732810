import { notifications } from "@mantine/notifications";
import { isFulfilled, isRejectedWithValue } from "@reduxjs/toolkit";

export const ApiMiddleware = (_store) => (next) => (action) => {
  const { type, payload, meta } = action;
  if (type?.includes("/pending")) {
    console.log("API request is pending...");
  } else if (isFulfilled(action) && meta?.arg?.type === "mutation") {
    notifications?.show({
      title: "Succsess",
      color: "green",
      message: payload?.message || "Action successfully",
      // message: payload?.message || payload?.data || "Action successfully",
    });
  } else if (isRejectedWithValue(action) && meta?.arg?.type === "mutation") {
    notifications?.show({
      title: "API Error",
      color: "red",
      message: payload?.message || "Something went wrong",
      // message: payload?.message || payload?.data?.message || "Something went wrong",
    });
  }
  next(action);
};

import { Indicator, Menu, Loader, Avatar } from "@mantine/core";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { GoCheckCircle, GoXCircle } from "react-icons/go";
import { useGetNotificationsListQuery } from "../../store/api/Home/NotificationApi";

// Define notification types
const notificationTypes = {
  connection_request: {
    slug: "Connection Request",
    icon: <i className="bi bi-person-plus text-base text-white"></i>,
  },
  message_received: {
    slug: "Message Received",
    icon: <i className="bi bi-envelope-fill text-base text-white"></i>,
  },
  article_review: {
    slug: "Article Review",
    icon: <i className="bi bi-journal-text text-base text-white"></i>,
  },
  education_review: {
    slug: "Education Review",
    icon: <i className="bi bi-book text-base text-white"></i>,
  },
  follow: {
    slug: "Follow",
    icon: <i className="bi bi-heart-fill text-base text-white"></i>,
  },
};

export default function NotificationMenu() {
  const [openNotification, setOpenNotification] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    data: notifications,
    isLoading,
    error,
  } = useGetNotificationsListQuery();

  // Sample notifications data
  const notificationsData = notifications?.data || [];
  const notificationsDataLen = notifications?.data?.length || 0;

  // Filter out notifications of types "connection_accepted", "connection_rejected", and "mention_in_post"
  const filteredNotifications = notificationsData.filter(
    (notification) =>
      ![
        "connection_accepted",
        "connection_rejected",
        "mention_in_post",
      ].includes(notification.notification_type)
  );

  // Sort notifications by date in descending order
  const sortedNotifications = filteredNotifications.sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  );

  // Limit the notifications displayed in the menu to the first 3
  const limitedNotifications = sortedNotifications.slice(0, 3);

  // Handle redirect to profile
  const handleProfileRedirect = (userId, userType) => {
    const userNavigate = {
      HCP: "hcp-profile",
      Center: "hcc-profile",
      Hospital: "mc-profile",
    };

    navigate(`/${userNavigate[userType]}`, {
      state: { userID: userId },
    });
  };

  // Handle connection accept or reject
  const handleConnectionResponse = (response, userId) => {
    console.log(`Connection ${response} for user ${userId}`);
    // Add your API call to accept or reject the connection here
  };

  return (
    <>
      <Menu
        shadow="md"
        width={300}
        position="bottom-end"
        offset={1}
        withArrow
        arrowPosition="center"
        opened={openNotification}
        onChange={setOpenNotification}
      >
        <Menu.Target>
          <Indicator
            inline
            color="#012e54"
            size={14}
            label={filteredNotifications.length}
            position="top-end"
            className="cursor-pointer hover:bg-bgmain hover:rounded-full hover:w-auto p-1"
          >
            <i className="bi bi-bell-fill text-main text-xl"></i>
          </Indicator>
        </Menu.Target>

        <Menu.Dropdown className="h-auto">
          <Menu.Label>Notifications</Menu.Label>
          {limitedNotifications.length === 0 ? (
            <Menu.Item
              className="w-100 !bg-body p-2 rounded-1 "
              leftSection={
                <i className="bi bi-x-circle-fill text-deepred text-base"></i>
              }
            >
              <h6 className="text-submain text-xs font-title m-0">
                There are no notifications yet!
              </h6>
            </Menu.Item>
          ) : (
            limitedNotifications.map((notification) => {
              const notificationType =
                notificationTypes[notification.notification_type];
              return (
                <Menu.Item
                  key={notification.id}
                  className="w-100 !bg-body p-2 rounded-1"
                  onClick={() =>
                    handleProfileRedirect(
                      notification.user_id,
                      notification.notifiable_type
                    )
                  }
                  style={{ cursor: "pointer" }}
                >
                  <div className="flex items-center gap-2">
                    <Avatar className="bg-main" radius="xl" size={24}>
                      {notificationType.icon}
                    </Avatar>
                    <div className="flex-grow">
                      <h6 className="text-main text-xs font-title m-0">
                        {notificationType.slug}
                      </h6>
                      <p className="text-text text-xs font-paragarphFont w-75">
                        {notification.notification_data}
                      </p>
                    </div>
                    {notification.notification_type ===
                      "connection_request" && (
                      <div className="flex gap-2">
                        <GoXCircle
                          size={26}
                          color="#e63946"
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent the menu item click event
                            handleConnectionResponse(
                              "reject",
                              notification.user_id
                            );
                          }}
                        />
                        <GoCheckCircle
                          color="#37e0b1"
                          size={26}
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent the menu item click event
                            handleConnectionResponse(
                              "accept",
                              notification.user_id
                            );
                          }}
                        />
                      </div>
                    )}
                  </div>
                </Menu.Item>
              );
            })
          )}
          {notificationsDataLen > 3 && (
            <Menu.Label
              color="gray"
              className="w-100 me-auto ms-auto bg-light-subtle h-auto p-1 mt-2 text-sm cursor-pointer text-center"
            >
              <Link
                to="/notifications"
                className="no-underline hover:underline"
                style={{ color: "#868e96" }}
              >
                Show all notifications <i className="bi bi-arrow-right"></i>
              </Link>
            </Menu.Label>
          )}
        </Menu.Dropdown>
      </Menu>
    </>
  );
}

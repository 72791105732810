import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "../Auth/ConfigApi";
import Cookies from "js-cookie";
export const Educations = createApi({
  reducerPath: "Educations",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Educations", "single", "reviews"],

  endpoints: (builder) => ({
    upcomingEducation: builder.query({
      query: (params) => ({
        method: "GET",
        url: `/educations/upcoming-education`,
        params,
      }),
      providesTags: ["Educations"],
    }),
    educationSearch: builder.query({
      query: (params) => ({
        method: "GET",
        url: `/educations`,
        params,
      }),
      providesTags: ["Educations"],
    }),
    singleEducation: builder.query({
      query: (params) => ({
        method: "GET",
        url: `/educations/${params}`,
        headers: {
          Accept: "application/json",
        },
      }),
      providesTags: ["single"],
    }),
    //----------------------------------------------------
    likesOnEducation: builder.mutation({
      query: (params) => ({
        method: "POST",
        url: `/educations/like/${params}`,
      }),
      invalidatesTags: ["single"],
    }),
    disLikesOnEducation: builder.mutation({
      query: (params) => ({
        method: "POST",
        url: `/educations/dislike/${params}`,
      }),
      invalidatesTags: ["single"],
    }),
    //----------------------------------------------------
    addEducationToBookmark: builder.mutation({
      query: (education_id) => ({
        method: "POST",
        url: `/educations/bookmarks/add?education_id=${education_id}`,
      }),
      invalidatesTags: ["single"],
    }),
    removeEducationFromBookmark: builder.mutation({
      query: (education_id) => ({
        method: "POST",
        url: `/educations/bookmarks/delete?education_id=${education_id}`,
      }),
      invalidatesTags: ["single"],
    }),
    //----------------------------------------------------
    enrollNow: builder.mutation({
      query: (education_id) => ({
        method: "POST",
        url: `/educations/enroll/${education_id}`,
      }),
      invalidatesTags: ["single"],
    }),
    //----------------------------------------------------
    fetchReviewsEducations: builder.query({
      query: ({ education_id, page }) => ({
        method: "GET",
        url: `/educations/reviews?education_id=${education_id}&page=${page}`,
      }),
      providesTags: ["reviews"],
    }),
    addReviewEducations: builder.mutation({
      query: ({ education_id, review }) => ({
        method: "POST",
        url: `/educations/reviews?education_id=${education_id}`,
        body: review,
      }),
      invalidatesTags: ["single", "reviews"],
    }),
    updateReviewEducation: builder.mutation({
      query: ({ education_id, review_id, review }) => ({
        method: "POST",
        url: `/educations/reviews/update?education_id=${education_id}&review_id=${review_id}`,
        body: review,
      }),
      invalidatesTags: ["single", "reviews"],
    }),
    deleteReviewEducation: builder.mutation({
      query: ({ education_id, review_id }) => ({
        method: "POST",
        url: `/educations/reviews/delete?education_id=${education_id}&review_id=${review_id}`,
      }),
      invalidatesTags: ["single", "reviews"],
    }),

    // Create Education
    createEducation: builder.mutation({
      query: (params) => ({
        method: "POST",
        url: `/educations/create`,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authorization: `Bearer ${Cookies.get("access_token")}`,
        },
        body: params,
      }),
      invalidatesTags: ["Educations", "single"],
    }),

    deleteEducation: builder.mutation({
      query: (params) => ({
        method: "POST",
        url: `/educations/delete`,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authorization: `Bearer ${Cookies.get("access_token")}`,
        },
        body: params,
      }),
      invalidatesTags: ["Educations", "single"],
    }),

    updateEducation: builder.mutation({
      query: (params) => ({
        method: "POST",
        url: `/educations/update`,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authorization: `Bearer ${Cookies.get("access_token")}`,
        },
        body: params,
      }),
      invalidatesTags: ["Educations", "single"],
    }),

    updateEducationModule: builder.mutation({
      query: (params) => ({
        method: "POST",
        url: `/educations/module/update`,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authorization: `Bearer ${Cookies.get("access_token")}`,
        },
        body: params,
      }),
      invalidatesTags: ["Educations", "single"],
    }),
    deleteEducationModule: builder.mutation({
      query: (params) => ({
        method: "POST",
        url: `/educations/module/delete`,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authorization: `Bearer ${Cookies.get("access_token")}`,
        },
        body: params,
      }),
      invalidatesTags: ["Educations", "single"],
    }),

    //lesson
    updateEducationLesson: builder.mutation({
      query: (params) => ({
        method: "POST",
        url: `/educations/lesson/update`,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authorization: `Bearer ${Cookies.get("access_token")}`,
        },
        body: params,
      }),
      invalidatesTags: ["Educations", "single"],
    }),

    deleteEducationLesson: builder.mutation({
      query: (params) => ({
        method: "POST",
        url: `/educations/lesson/delete`,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authorization: `Bearer ${Cookies.get("access_token")}`,
        },
        body: params,
      }),
      invalidatesTags: ["Educations", "single"],
    }),

    uploadFiles: builder.mutation({
      query: (params) => ({
        method: "POST",
        url: `/educations/upload/store`,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authorization: `Bearer ${Cookies.get("access_token")}`,
        },
        body: params,
      }),
      invalidatesTags: ["Educations", "single"],
    }),
  }),
});

export const {
  useEducationSearchQuery,
  useUpcomingEducationQuery,
  useSingleEducationQuery,

  useLikesOnEducationMutation,
  useDisLikesOnEducationMutation,

  useAddEducationToBookmarkMutation,
  useRemoveEducationFromBookmarkMutation,

  useEnrollNowMutation,

  useFetchReviewsEducationsQuery,
  useAddReviewEducationsMutation,
  useUpdateReviewEducationMutation,
  useDeleteReviewEducationMutation,

  useCreateEducationMutation,
  useDeleteEducationMutation,
  useUpdateEducationMutation,

  useUpdateEducationModuleMutation,
  useDeleteEducationModuleMutation,

  useUpdateEducationLessonMutation,
  useDeleteEducationLessonMutation,

  useUploadFilesMutation,
} = Educations;

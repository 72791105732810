import React, { useState } from "react";
import "./newsletter.css";
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";

// Import Images
import Mail from "../assets/images/Subscribe.svg";
import { useAddNewsLetterMutation } from "../store/api/Home/NewsletterApi";

export default function Newsletter() {
  const [email, setEmail] = useState("");

  // API Fetching
  const [Newsletter] = useAddNewsLetterMutation();

  const handleSubscribe = async () => {
    try {
      const userSubscription = await Newsletter({ email }).unwrap();
    } catch (error) {
      console.log(error.message || "Failed to subscribe.");
    }
  };

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <>
      <section className="newsletter">
        <Container fluid>
          <Row className="justify-content-center align-items-flex-start gap-sm-3">
            <Col lg="4" xl={5}>
              <div className="d-flex justify-content-center align-items-start gap-4 ">
                <img src={Mail} alt="Subscribe" height={"100"} />
                <div className="newsletter__text">
                  <h3>Subscribe</h3>
                  <p>to Our Newsletter</p>
                </div>
              </div>
            </Col>
            <Col lg="4" xl={5}>
              <InputGroup className="mb-3">
                <Form.Control
                  type="email"
                  placeholder="Enter your email address"
                  value={email}
                  onChange={handleChange}
                  aria-label="Enter your email address"
                  aria-describedby="basic-addon2"
                />
                <Button
                  onClick={() => {
                    handleSubscribe();
                  }}
                >
                  Subscribe
                </Button>
              </InputGroup>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

import React from "react";
import { useNavigate } from "react-router-dom";
import { BsExclamationCircleFill } from "react-icons/bs";
import { Modal } from "@mantine/core";
import { useLocation } from "react-router-dom";

export default function GuestUserModal({ opened, onClose }) {
  const divStyle = {
    content: {
      padding: '10px 0',
    }
  };
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      centered
      withCloseButton={false}
      overlayProps={{
        backgroundOpacity: 0.55,
        blur: 2,
      }}
      transitionProps={{
        transition: "fade",
        duration: 300,
        timingFunction: "linear",
      }}
      className="text-center"
      size={"500px"}
      styles={divStyle}
    >
      <BsExclamationCircleFill className="text-main text-4xl text-center m-auto my-3" />
      <h2 className="text-submain text-xl font-semibold font-title">
        Can't do this action!
      </h2>
      <p className="text-text text-base font-paragarphFont">
        You must SignIn First to do This Action.!
      </p>
      <div className="flex justify-center items-center gap-3">
        <button
          type="button"
          className="text-submain bg-gray-200 rounded-lg w-50 text-center py-2 mt-3 font-sans font-bold text-base max-[350px]:text-sm"
          onClick={() => {
            navigate("/sign-in", { state: { from: location } });
          }}
        >
          Go to Signin
        </button>
        <span className="text-[#d2d5da] text-md mt-3">or</span>
        <button
          type="button"
          className="text-white bg-main rounded-lg w-50 text-center py-2 mt-3 font-sans font-bold text-base max-[350px]:text-sm"
          onClick={() => {
            navigate("/sign-up", { state: { from: location } });
          }}
        >
          Go to SignUp
        </button>
      </div>
    </Modal>
  );
}

import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "../Auth/ConfigApi";

export const GroupDiscussion = createApi({
  reducerPath: "GroupDiscussion",
  baseQuery: baseQueryWithReauth,

  tagTypes: ["GroupDiscussion"],

  endpoints: (builder) => ({
    groupDiscussion: builder.query({
      query: (params) => ({
        url: `/home/groups`,
        params,
      }),
      providesTags: ["GroupDiscussion"],
    }),
  }),
});

export const { useGroupDiscussionQuery } = GroupDiscussion;

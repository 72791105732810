import React from "react";
import Header from "../../../Header/header";
import Newsletter from "../../../NewSletter/Newsletter";
import Footer from "../../../Footer/Footer";
import CourseForm from "./CourseForm";
export default function AddEducationForm() {
  return (
    <>
      <Header />
      <CourseForm />
      <Newsletter />
      <Footer />
    </>
  );
}

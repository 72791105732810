import React, { useEffect, useState } from "react";
import "./footer.css";
import { Col, Container, ListGroup, ListGroupItem, Row } from "react-bootstrap";

// Import Image
import Logo from "../assets/images/logo.svg";
import Twitter from "../assets/images/Twitter.svg";
import Youtube from "../assets/images/Youtube.svg";
import Facebook from "../assets/images/Facebook.svg";
import LinkedIn from "../assets/images/Linkedin.svg";
import LiveBar from "./LiveBar";

const Footeras = [
  {
    id: 1,
    title: "Medicaspace",
    a: [
      {
        id: 1,
        title: "Contact us",
      },
      {
        id: 2,
        title: "About us",
      },
      {
        id: 3,
        title: "Support",
      },
      {
        id: 4,
        title: "Career",
      },
      {
        id: 5,
        title: "Partnership",
      },
    ],
    // path: "/",
  },

  {
    id: 2,
    title: "Know More",
    a: [
      {
        id: 1,
        title: "Scoring points",
      },
      {
        id: 2,
        title: "Pricing",
      },
      {
        id: 3,
        title: "Business solution",
      },
      {
        id: 4,
        title: "Terms&Conditions",
      },
      {
        id: 5,
        title: "Premium",
      },
    ],
    // path: "/",
  },

  {
    id: 3,
    title: "Ask For",
    a: [
      {
        id: 1,
        title: "Marketing campaign",
      },
      {
        id: 2,
        title: "Marketing Material",
      },
      {
        id: 3,
        title: "Live Streaming",
      },
      {
        id: 4,
        title: "Banners",
      },
      {
        id: 5,
        title: "Internship",
      },
    ],
    // path: "/",
  },
];
export default function Footer() {
  const [isVisible, setIsVisible] = useState(false);

  // Show button when user scrolls down 100px
  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  // Scroll to top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <footer className="footer">
        {isVisible && (
          <div className="arrowbtn" onClick={scrollToTop} title="Go to top">
            <i className="bi bi-arrow-up"></i>
          </div>
        )}

        <Container fluid>
          <Row className="gap-xl-4">
            <Col lg="5" className="footer__info">
              <div className="logo">
                <img alt="" src={Logo} width="235" height="57" />{" "}
              </div>
              <div className="footer__decsription">
                <h4 className="footer__title">Medicaspace</h4>
                <p>
                At Medicaspace, we believe that healthcare is a global endeavor. Medicaspace is designed to
                reshape the communication and networking among healthcare professionals, healthcare
                corporations, and healthcare providers from around the world. We offer a wide range of
                innovative features, and services that help to share dedicated information, experience,
                knowledge to leverage communication, networking and add dedicated digital marketing and
                trading solution at healthcare environment.
                </p>
              </div>
            </Col>
            {Footeras.map((as) => {
              return (
                <Col lg="2" key={as.id}>
                  <h5 className="footer__a-title">{as.title}</h5>

                  <ListGroup className="footer__quick-as">
                    {as.a.map((aItem) => {
                      return (
                        <ListGroupItem
                          className="ps-0 border-0"
                          key={aItem.id}
                        >
                          {aItem.title}
                        </ListGroupItem>
                      );
                    })}
                  </ListGroup>
                </Col>
              );
            })}

            <Col lg="1" className="social__icons mb-3">

              <a href="https://x.com/OMedicaspace?t=98I1-qShvRILwZA0Kc0xdQ&s=08" target="_blank"rel="noopener noreferrer">
                <img
                  alt=""
                  src={Twitter}
                  className="d-inline-block align-top"
                  width={"18"}
                />
              </a>

              <a href="https://www.youtube.com/@medicaspacededicatedmedica1576" target="_blank"rel="noopener noreferrer">
                <img
                  alt=""
                  src={Youtube}
                  className="d-inline-block align-top"
                  width={"18"}
                />
              </a>

              <a href="https://www.facebook.com/Medicaspace.official?mibextid=LQQJ4d" target="_blank"rel="noopener noreferrer">
                <img
                  alt=""
                  src={Facebook}
                  className="d-inline-block align-top facebook-icon"
                />
              </a>

              <a href="https://www.linkedin.com/company/medicaspace/" target="_blank"rel="noopener noreferrer">
                <img
                  alt=""
                src={LinkedIn}
                  className="d-inline-block align-top"
                  width={"18"}
                />
              </a>

            </Col>
          </Row>
        </Container>
      </footer>
      {/* <LiveBar /> */}
    </>
  );
}

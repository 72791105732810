import React from "react";
import "./App.css";
import Routers from "./Routers/Routers";

function App() {

//To close Inspect//
//   useEffect(() => {
//     const handleKeyDown = (e) => {
//         if (e.key === 'F12' || (e.ctrlKey && (e.key === 'U' || e.key === 'I'))) {
//             e.preventDefault();
//         }
//     };
//     document.oncontextmenu = () => false; // Disable right-click
//     window.addEventListener('keydown', handleKeyDown);
//     return () => {
//         window.removeEventListener('keydown', handleKeyDown);
//     };
// }, []);

  return (
    <>
      <Routers />
    </>
  );
}

export default App;

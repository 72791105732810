import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "../Auth/ConfigApi";
import Cookies from "js-cookie";

export const MedicalCenterApi = createApi({
  reducerPath: "MedicalCenter",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["MedicalCenter,FollowStatus"],

  endpoints: (builder) => ({
    getCentersListing: builder.query({
      query: (params) => ({
        url: `/centers`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${Cookies.get("access_token")}`,
        },
        params,
      }),
      providesTags: ["MedicalCenter,FollowStatus"],
    }),

    getTopRated: builder.query({
      query: () => ({
        url: `/centers/top-rated`,
      }),
      providesTags: ["MedicalCenter"],
    }),

    getCentersFollowing: builder.query({
      query: (params) => ({
        url: `/centers/followings`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${Cookies.get("access_token")}`,
        },
        params,
      }),
      providesTags: ["MedicalCenter"],
    }),
  }),
});

export const {
  useGetCentersListingQuery,
  useGetTopRatedQuery,
  useGetCentersFollowingQuery,
} = MedicalCenterApi;

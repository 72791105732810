import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "../Auth/ConfigApi";

export const UserNewsLetter = createApi({
    reducerPath: "UserNewsLetter",
    baseQuery: baseQueryWithReauth,
    tagTypes: ["userNewsLetter"],

    endpoints: (builder) => ({
        subscribeUserNewsLetter: builder.mutation({
        query: (user_id) => ({
        method: "POST",
        url: `/subscribe/${user_id}`,
        }),
        invalidatesTags: ["userNewsLetter"],
    }),
    unsubscribeUserNewsLetter: builder.mutation({
        query: (user_id) => ({
        method: "POST",
        url: `/unsubscribe/${user_id}`,
        }),
        invalidatesTags: ["userNewsLetter"],
    }),
    }),
});

export const { 
    useSubscribeUserNewsLetterMutation,
    useUnsubscribeUserNewsLetterMutation,
} = UserNewsLetter;

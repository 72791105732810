import { FileInput, Image, Select, TextInput } from "@mantine/core";
import { HiOutlinePhoto } from "react-icons/hi2";
import { DatePickerInput } from "@mantine/dates";
import React, { useEffect, useState } from "react";
import { PiCurrencyDollar } from "react-icons/pi";
import { IoIosAddCircleOutline, IoMdCloseCircleOutline } from "react-icons/io";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../Context/authContext";
import {
  useCreateEducationMutation,
  useSingleEducationQuery,
  useUpdateEducationLessonMutation,
  useUpdateEducationModuleMutation,
  useUpdateEducationMutation,
  useUploadFilesMutation,
} from "../../../store/api/Home/EducationsApi";
import { BiSolidEdit } from "react-icons/bi";

const unsafeInputRegex =
  /(<script\b[^>]*>.*?<\/script>|<\/?\w+[^>]*>|style\s*=\s*["'][^"']*["']|javascript:|data:text\/html|select\s+\*\s+from\s+\w+|insert\s+into\s+\w+\s+\(.*\)\s+values\s+\(.*\)|update\s+\w+\s+set\s+.*=.*|delete\s+from\s+\w+|drop\s+table\s+\w+|union\s+select\s+.*|;\s*|--|\/\*.*?\*\/|#|\$)/i;

const validateInput = (value) => {
  const containsString = /[a-zA-Z]/.test(value);
  const containsNumber = /[0-9]/.test(value);
  const stringValue = value?.trim();

  if (typeof value !== "string") {
    return "Course Title is required";
  }
  if (!stringValue) {
    return `Field can't be empty`;
  }

  if (!containsString || (!containsString && !containsNumber)) {
    return "Input cannot be only numerical";
  }

  if (stringValue?.length < 10 || stringValue?.length > 250) {
    return "Input length must be between 10 and 250 characters";
  }

  if (unsafeInputRegex?.test(value)) {
    return "Invalid input: Potentially harmful content detected.";
  }

  return null;
};

export default function CourseForm() {
  const { user } = useAuth();
  const { id } = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const chapterID = queryParams.get("chapter");
  const lessonID = queryParams.get("lesson");
  const isDisabled = id && !chapterID;

  const navigate = useNavigate();
  // const location = useLocation();
  // const userID = location?.state?.userID;

  const [previewImage, setPreviewImage] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const moduleIndex = localStorage.getItem("module_index");
  const lessonIndex = localStorage.getItem("lesson_index");
  const active_module_index = localStorage.getItem("active_module_index");

  const defaultLesson = {
    name: "",
    lesson_details: "",
    type_id: "",
    attachment_url: "",
  };

  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    reset,
    setError,
    clearErrors,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      modules: [
        {
          name: "",
          description: "",
          lessons: [defaultLesson],
        },
      ],
    },
  });

  const startDate = watch("start_date");
  const selectedType = watch("type");

  const { fields: moduleFields, append: appendModule } = useFieldArray({
    control,
    name: "modules",
  });

  const {
    fields: lessonFields,
    append: appendLesson,
    remove: removeLesson,
  } = useFieldArray({
    control,
    name: `modules[0].lessons`,
  });

  const [AddCourse] = useCreateEducationMutation();
  const [UpdateEducation] = useUpdateEducationMutation();
  const [UpdateModule] = useUpdateEducationModuleMutation();
  const [UpdateLesson] = useUpdateEducationLessonMutation();
  const [UploadFiles] = useUploadFilesMutation();
  const { data: singleEducation } = useSingleEducationQuery(id, { skip: !id }); //id, { skip: !id }

  const onFileChange = async (file, moduleIndex, lessonIndex) => {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("type", "file");

      try {
        const response = await UploadFiles(formData).unwrap();
        if (response?.file) {
          // Assuming response returns file URL, update form with the file URL
          setValue(
            `modules[${moduleIndex}].lessons[${lessonIndex}].attachment_url`,
            response.file
          );
        }
      } catch (error) {
        console.error("File upload failed:", error);
      }
    }
  };

  const [titleLength, setTitleLength] = useState(
    singleEducation?.name?.length || 0
  );

  const [descriptionLength, setDescriptionLength] = useState(
    singleEducation?.description?.length || 0
  );

  const handleTitleChange = (e) => {
    const value = e.target.value;
    setTitleLength(value.length);
    const validationError = validateInput(value);
    if (validationError) {
      setError("name", { message: validationError });
    } else {
      clearErrors("name");
    }
  };

  const handleDescriptionChange = (e) => {
    const value = e.target.value;
    setDescriptionLength(value.length);
    if (value?.trim()?.length >= 10 && value?.trim()?.length <= 250) {
      clearErrors("description");
    } else if (value?.trim()?.length < 10 || value?.trim()?.length > 250) {
      setError("description", {
        message: "Input length must be between 10 and 250 characters",
      });
    }
  };

  const onSubmit = async (data) => {
    console.log(data);
    try {
      if (data.start_date) {
        data.start_date = data.start_date.toISOString().split("T")[0];
      }
      if (data.end_date) {
        data.end_date = data.end_date.toISOString().split("T")[0];
      }

      // const file = data.modules[0]?.lessons[0]?.attachment_url;
      // if (file) {
      //   const formData = new FormData();
      //   formData.append("file", file);
      //   formData.append("type", "file");

      //   const uploadResult = await UploadFiles(formData).unwrap();
      //   console.log("File uploaded successfully:", uploadResult);

      //   // Attach the uploaded file URL to the lesson data
      //   data.modules[0].lessons[0].attachment_url = uploadResult.url;
      // }

      const additionalData = {
        speciality_id: user?.speciality?.[0]?.id,
        instructor_name: user?.name,
        image: imageUrl,
        education_id: id,
      };

      const moduleData = {
        name: data.modules.map((module) => module.name)[0],
        description: data.modules.map((module) => module.description)[0],
      };

      const lessonData = {
        name: data.modules[0].lessons.map((lesson) => lesson.name)[0],
        type_id: data.modules[0].lessons.map((lesson) => lesson.type_id)[0],
        lesson_details: data.modules[0].lessons.map(
          (lesson) => lesson.lesson_details
        )[0],
        lesson_duration: 10,
        attachment_url: data.modules[0].lessons.map(
          (lesson) => lesson.attachment_url
        )[0],
      };

      const courseData = {
        modules: data.modules.map((module) => ({
          name: module.name,
          description: module.description,
        })),
        lessons: data.modules.flatMap((module, moduleIndex) =>
          module.lessons.map((lesson) => ({
            name: lesson.name,
            module_index: moduleIndex,
            type_id: lesson.type_id,
            lesson_details: lesson.lesson_details,
            lesson_duration: 10,
            attachment_url: lesson.attachment_url,
          }))
        ),
      };

      const educationeData = { ...data, ...additionalData, ...courseData };
      const updatedCourseData = { ...data, ...additionalData };
      const updatedModuleData = { ...data, ...moduleData };
      const updatedLessonData = { ...data, ...lessonData };

      if (id && !chapterID && !lessonID) {
        const result = await UpdateEducation(updatedCourseData).unwrap();
        reset();
        if (result?.id) navigate(`/course/${result?.id}`);
      } else if (chapterID && !lessonID) {
        await UpdateModule({
          ...updatedModuleData,
          education_id: id,
          module_id: chapterID,
        }).unwrap();
        if (singleEducation?.id) navigate(`/course/${singleEducation?.id}`);
      } else if (lessonID && !chapterID) {
        console.log("Calling UpdateLesson");
        await UpdateLesson({
          ...updatedLessonData,
          education_id: id,
          lesson_id: lessonID,
        }).unwrap();
        if (singleEducation?.id) navigate(`/course/${singleEducation?.id}`);
      } else {
        const result = await AddCourse(educationeData).unwrap();
        if (result?.id) navigate(`/course/${result?.id}`);
      }
      reset();
    } catch (error) {
      console.error("Failed to add education:", error);
    }
  };

  useEffect(() => {
    if (id && !chapterID && !lessonID) {
      reset({
        name: singleEducation?.name || "",
        description: singleEducation?.description || "",
        type: singleEducation?.type || null,
        price: singleEducation?.price || "",
        start_at: singleEducation?.start_at
          ? new Date(singleEducation.start_at)
          : null,
        end_date: singleEducation?.end_date
          ? new Date(singleEducation.end_date)
          : null,
        image: singleEducation?.image,
      });
    } else if (chapterID) {
      if (singleEducation.Modules?.data?.length) {
        const module = singleEducation?.Modules?.data[moduleIndex];
        setValue(`modules[0].name`, module.name || "");
        setValue(`modules[0].description`, module.description || "");
      }
    } else if (lessonID) {
      if (
        singleEducation?.Modules?.data[active_module_index]?.lessons?.data
          ?.length
      ) {
        const lesson =
          singleEducation?.Modules?.data[active_module_index]?.lessons?.data[
            lessonIndex
          ];

        setValue(`modules[0].lessons[0].name`, lesson.name || "");
        setValue(
          `modules[0].lessons[0].lesson_details`,
          lesson.lesson_details || ""
        );
        setValue(`modules[0].lessons[0].type_id`, lesson.type_id || "");
        setValue(
          `modules[0].lessons[0].attachment_url`,
          lesson.attachment_url || ""
        );
      }
    }
  }, [
    reset,
    singleEducation,
    chapterID,
    id,
    moduleIndex,
    lessonID,
    lessonIndex,
    active_module_index,
    setValue,
  ]);

  const appendModuleWithLesson = () => {
    appendModule({
      name: "",
      description: "",
      lessons: [defaultLesson],
    });
  };

  // Add new lesson to the specific module index
  const handleAddLesson = (moduleIndex) => {
    appendLesson(moduleIndex, {
      name: "",
      lesson_details: "",
      type_id: "",
      attachment_url: "",
    });
  };

  return (
    <>
      <section className="py-28 px-20 w-full max-lg:px-2 sm:max-lg:px-20">
        <div className="w-full">
          <h4 className="text-hoverColor text-2xl font-bold font-title mb-2">
            {id && singleEducation ? "Update Course" : " New Course"}
          </h4>
          <form
            className="mt-4"
            onSubmit={handleSubmit(onSubmit)}
            encType="multipart/form-data"
          >
            <div className="flex flex-col-reverse justify-between lg:flex-row lg:items-start lg:gap-8 w-full">
              <div className="flex flex-col w-full lg:w-2/3">
                <div className="flex flex-col w-full">
                  {/* Title */}
                  <div>
                    <TextInput
                      name="name"
                      label="Title"
                      placeholder="Add Course Title"
                      className="w-full"
                      classNames={{ input: "py-4 !rounded-lg w-full" }}
                      disabled={chapterID || lessonID}
                      {...register("name", {
                        required: {
                          value: chapterID || lessonID ? false : true,
                          message: "Please insert course title",
                        },
                      })}
                      onChange={handleTitleChange}
                      error={errors?.name?.message}
                    />
                    <div className="text-gray-500 text-xs mt-1">
                      {titleLength} / 250 characters
                    </div>
                    {titleLength > 250 && (
                      <div className="text-red-500 text-xs mt-1 font-bold">
                        Title is too long
                      </div>
                    )}
                  </div>

                  {/* Description */}
                  <div>
                    <TextInput
                      mt="xl"
                      name="description"
                      label="Description"
                      placeholder="Add Course Description Here.."
                      className="w-full "
                      classNames={{ input: "py-4 !rounded-lg w-full !h-16" }}
                      disabled={chapterID || lessonID}
                      {...register("description", {
                        required: {
                          value: chapterID || lessonID ? false : true,
                          message: "Please insert course description.",
                        },
                      })}
                      onChange={handleDescriptionChange}
                      error={errors?.description?.message}
                    />
                    <div className="text-gray-500 text-xs mt-1">
                      {descriptionLength} / 250 characters
                    </div>
                    {descriptionLength > 250 && (
                      <div className="text-red-500 text-xs mt-1 font-bold">
                        Small Description is too long
                      </div>
                    )}
                  </div>
                  {/* Date */}
                  <div className="flex flex-col justify-between items-center md:flex-row gap-4">
                    <div className="flex flex-col justify-between items-end sm:flex-row lg:flex-col xl:flex-row gap-4 w-full">
                      <Controller
                        name={id && singleEducation ? "start_at" : "start_date"}
                        control={control}
                        rules={{
                          required: {
                            value: chapterID || lessonID ? false : true,
                            message: `Please insert start date`,
                          },
                        }}
                        render={({ field }) => (
                          <DatePickerInput
                            {...field}
                            mt="xl"
                            name="start_date"
                            label="Starting Date "
                            placeholder="Start Date"
                            disabled={chapterID || lessonID}
                            className="w-full"
                            clearable
                            classNames={{ input: "!py-4 !rounded-lg !w-full" }}
                            error={errors?.start_date?.message}
                          />
                        )}
                      />
                    </div>

                    <div className="flex flex-col justify-between items-end sm:flex-row lg:flex-col xl:flex-row  gap-4 w-full">
                      <Controller
                        name="end_date"
                        control={control}
                        rules={{
                          required: {
                            value: chapterID || lessonID ? false : true,
                            message: `Please insert end date`,
                          },
                          validate: {
                            notEqualToStartDate: (value) =>
                              !value ||
                              !startDate ||
                              value >= startDate ||
                              "End date cannot be earlier than start date.",
                            notSameAsStartDate: (value) =>
                              !value ||
                              !startDate ||
                              value.toISOString().split("T")[0] !==
                                startDate.toISOString().split("T")[0] ||
                              "End date cannot be the same as start date.",
                          },
                        }}
                        render={({ field }) => (
                          <DatePickerInput
                            {...field}
                            mt="xl"
                            name="end_date"
                            label="Ending Date "
                            placeholder="End Date"
                            className="w-full"
                            disabled={chapterID || lessonID}
                            clearable
                            classNames={{ input: "!py-4 !rounded-lg !w-full" }}
                            error={errors?.end_date?.message}
                          />
                        )}
                      />
                    </div>
                  </div>

                  {/* Category and price */}
                  <div className=" flex flex-col justify-between items-center md:flex-row gap-4">
                    <div className="flex flex-col justify-between items-end sm:flex-row lg:flex-col xl:flex-row gap-4 w-full">
                      <Controller
                        name="type"
                        control={control}
                        rules={{
                          required: {
                            value: chapterID || lessonID ? false : true,
                            message: `Please insert course category`,
                          },
                        }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            mt="xl"
                            name="type"
                            label="Category"
                            placeholder="Add Course Category.."
                            data={[
                              { value: "free", label: "Free" },
                              { value: "paid", label: "Paid" },
                              { value: "premium", label: "Premium" },
                            ]}
                            disabled={chapterID || lessonID}
                            clearable
                            searchable
                            rightSectionWidth={30}
                            className="w-full"
                            classNames={{ input: "!py-6 !rounded-lg !w-full" }}
                            error={errors?.type?.message}
                          />
                        )}
                      />
                    </div>

                    {selectedType === "paid" && (
                      <div className="flex flex-col justify-between items-end sm:flex-row lg:flex-col xl:flex-row gap-4 w-full">
                        <TextInput
                          name="price"
                          mt="xl"
                          label="Course Price"
                          placeholder="Add Course price"
                          disabled={chapterID || lessonID}
                          className="w-full"
                          classNames={{ input: "py-4 !rounded-lg !w-full" }}
                          leftSection={<PiCurrencyDollar size={16} />}
                          {...register("price", {
                            required: {
                              value: chapterID || lessonID ? false : true,
                              message: "Please insert course price.",
                            },
                            pattern: {
                              value: /^\d*$/,
                              message: "Only numeric values are allowed",
                            },
                          })}
                          error={errors?.price?.message}
                        />
                      </div>
                    )}
                  </div>
                </div>

                {/* Chapter & Lessons */}

                <div>
                  {moduleFields.map((module, moduleIndex) => (
                    <div key={module.id} className="content-section">
                      {/* chapter title */}
                      <div className=" w-full mb-4 mt-4">
                        <h5 className="text-hoverColor text-base font-bold font-title mb-0">
                          {" "}
                          New Module
                        </h5>
                      </div>
                      <div className="chapter">
                        {/* chapter input */}
                        <div>
                          <div>
                            <TextInput
                              mt="lg"
                              label="Module Title"
                              placeholder="Add Module Title"
                              className="w-full"
                              disabled={isDisabled}
                              classNames={{
                                input: "py-4 !rounded-lg w-full",
                              }}
                              {...register(`modules[${moduleIndex}].name`, {
                                required: {
                                  value: id && singleEducation ? false : true,
                                  message: "Please insert module title",
                                },
                              })}
                              onChange={(e) => {
                                const value = e.target.value;
                                const validationError = validateInput(value);
                                if (validationError) {
                                  setError(`modules[${moduleIndex}].name`, {
                                    message: validationError,
                                  });
                                } else {
                                  clearErrors(`modules[${moduleIndex}].name`);
                                }
                              }}
                              error={
                                errors?.modules?.[moduleIndex]?.name?.message
                              }
                            />
                            {/* <div className="text-gray-500 text-xs mt-1">
                              {titleLength} / 250 characters
                            </div>
                            {titleLength > 250 && (
                              <div className="text-red-500 text-xs mt-1 font-bold">
                                Title is too long
                              </div>
                            )} */}
                          </div>

                          <div>
                            <TextInput
                              mt="xl"
                              label="Module Description"
                              placeholder="Add Module Description Here.."
                              className="w-full"
                              classNames={{
                                input: "py-4 !rounded-lg w-full !h-16",
                              }}
                              disabled={isDisabled}
                              {...register(
                                `modules[${moduleIndex}].description`,
                                {
                                  required: {
                                    value: id && singleEducation ? false : true,
                                    message: "Please insert module description",
                                  },
                                }
                              )}
                              onChange={(e) => {
                                const value = e.target.value;
                                const validationError = validateInput(value);
                                if (validationError) {
                                  setError(
                                    `modules[${moduleIndex}].description`,
                                    {
                                      message: validationError,
                                    }
                                  );
                                } else {
                                  clearErrors(
                                    `modules[${moduleIndex}].description`
                                  );
                                }
                              }}
                              error={
                                errors?.modules?.[moduleIndex]?.description
                                  ?.message
                              }
                            />
                            {/* <div className="text-gray-500 text-xs mt-1">
                              {descriptionLength} / 250 characters
                            </div>
                            {descriptionLength > 250 && (
                              <div className="text-red-500 text-xs mt-1 font-bold">
                                Small Description is too long
                              </div>
                            )} */}
                          </div>
                        </div>
                      </div>

                      {/* lesson */}

                      {lessonFields.map((lesson, lessonIndex) => (
                        <div
                          key={lesson.id}
                          className="lesson ms-8 ps-8 border-l-2 border-[#f5f5f5]"
                        >
                          <div className="w-full mb-4 mt-4 flex justify-between items-center ">
                            <h5 className="text-hoverColor text-base font-bold font-title mb-0">
                              New Lesson {lessonIndex + 1}
                            </h5>
                            {lessonIndex > 0 && (
                              <div
                                className="text-red font-paragarphFont text-hoverColor cursor-pointer"
                                onClick={() => removeLesson(lessonIndex)}
                              >
                                <IoMdCloseCircleOutline size={20} />
                              </div>
                            )}
                          </div>
                          {/* Lesson Content */}
                          <div>
                            <div>
                              <TextInput
                                label="Lesson Title"
                                placeholder="Add Lesson Title"
                                className="w-full"
                                disabled={!lessonID && id}
                                classNames={{
                                  input: "py-4 !rounded-lg w-full",
                                }}
                                {...register(
                                  `modules[${moduleIndex}].lessons[${lessonIndex}].name`,
                                  {
                                    required: {
                                      value:
                                        id && singleEducation ? false : true,
                                      message:
                                        "Please insert your lesson title",
                                    },
                                  }
                                )}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  const validationError = validateInput(value);
                                  if (validationError) {
                                    setError(
                                      `modules[${moduleIndex}].lessons[${lessonIndex}].name`,
                                      {
                                        message: validationError,
                                      }
                                    );
                                  } else {
                                    clearErrors(
                                      `modules[${moduleIndex}].lessons[${lessonIndex}].name`
                                    );
                                  }
                                }}
                                error={
                                  errors?.modules?.[moduleIndex]?.lessons?.[
                                    lessonIndex
                                  ]?.name?.message
                                }
                              />
                              {/* <div className="text-gray-500 text-xs mt-1">
                                {titleLength} / 250 characters
                              </div>
                              {titleLength > 250 && (
                                <div className="text-red-500 text-xs mt-1 font-bold">
                                  Title is too long
                                </div>
                              )}
                                 */}
                            </div>

                            <div>
                              <TextInput
                                mt="xl"
                                label="Lesson Description"
                                placeholder="Add Lesson Description"
                                className="w-full "
                                disabled={!lessonID && id}
                                classNames={{
                                  input: "py-4 !rounded-lg w-full !h-16",
                                }}
                                {...register(
                                  `modules[${moduleIndex}].lessons[${lessonIndex}].lesson_details`,
                                  {
                                    required: {
                                      value:
                                        id && singleEducation ? false : true,
                                      message:
                                        "Please insert your lesson title",
                                    },
                                  }
                                )}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  const validationError = validateInput(value);
                                  if (validationError) {
                                    setError(
                                      `modules[${moduleIndex}].lessons[${lessonIndex}].lesson_details`,
                                      {
                                        message: validationError,
                                      }
                                    );
                                  } else {
                                    clearErrors(
                                      `modules[${moduleIndex}].lessons[${lessonIndex}].lesson_details`
                                    );
                                  }
                                }}
                                error={
                                  errors?.modules?.[moduleIndex]?.lessons?.[
                                    lessonIndex
                                  ]?.lesson_details?.message
                                }
                              />
                              {/* <div className="text-gray-500 text-xs mt-1">
                                {descriptionLength} / 250 characters
                              </div>
                              {descriptionLength > 250 && (
                                <div className="text-red-500 text-xs mt-1 font-bold">
                                  Small Description is too long
                                </div>
                              )} */}
                            </div>

                            <Controller
                              name={`modules[${moduleIndex}].lessons[${lessonIndex}].type_id`}
                              control={control}
                              rules={{
                                required: {
                                  value: id && singleEducation ? false : true,
                                  message: `Please insert your content type`,
                                },
                              }}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  mt="xl"
                                  label="Select Type"
                                  placeholder="Select the content type..."
                                  data={[
                                    { value: "1", label: "Video" },
                                    { value: "2", label: "File" },
                                  ]}
                                  clearable
                                  searchable
                                  rightSectionWidth={30}
                                  className="w-full"
                                  classNames={{
                                    input: "!py-6 !rounded-lg !w-full",
                                  }}
                                  disabled={!lessonID && id}
                                  error={
                                    errors?.modules?.[moduleIndex]?.lessons?.[
                                      lessonIndex
                                    ]?.type_id?.message
                                  }
                                />
                              )}
                            />

                            {/* <div className="mt-4">
                              <Controller
                                name={`modules[${moduleIndex}].lessons[${lessonIndex}].attachment_url`}
                                control={control}
                                {...register(
                                  `modules[${moduleIndex}].lessons[${lessonIndex}].attachment_url`,
                                  {
                                    required: {
                                      value:
                                        id && singleEducation ? false : true,
                                      message:
                                        "Please insert your lesson attatchment",
                                    },
                                  }
                                )}
                                render={({ field }) => (
                                  <FileInput
                                    {...field}
                                    label="Upload File / Attachment"
                                    placeholder={
                                      "Upload your course attachment"
                                    }
                                    description={
                                      watch(
                                        `modules[${moduleIndex}].lessons[${lessonIndex}].type_id`
                                      ) === "1"
                                        ? "You can upload videos"
                                        : watch(
                                            `modules[${moduleIndex}].lessons[${lessonIndex}].type_id`
                                          ) === "2"
                                        ? "You can upload docs | attachment | ppt | pdf files"
                                        : "upload file attachments"
                                    }
                                    accept={
                                      watch(
                                        `modules[${moduleIndex}].lessons[${lessonIndex}].type_id`
                                      ) === "1"
                                        ? "video/mp4, video/mov, video/avi, video/mkv, video/webm"
                                        : ".pdf, .doc, .docx, .odt, .ppt, .pptx, .odp, .txt, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.ms-powerpoint, application/pdf"
                                    }
                                    classNames={{
                                      input: "!rounded-lg w-full py-3",
                                    }}
                                    onChange={(file) => {
                                      field.onChange(file); // Update form state with file
                                      onFileChange(
                                        file,
                                        moduleIndex,
                                        lessonIndex
                                      ); // Trigger file upload request
                                    }}
                                    error={
                                      errors?.modules?.[moduleIndex]?.lessons?.[
                                        lessonIndex
                                      ]?.attachment_url?.message
                                    }
                                  />
                                )}
                              />
                            </div> */}

                            <div className="mt-4">
                              <TextInput
                                mt={"xl"}
                                type="url"
                                label="Lesson Attachment"
                                placeholder="Add Lesson Link Here..."
                                className="w-full"
                                classNames={{
                                  input: "py-4 !rounded-lg w-full",
                                }}
                                disabled={!lessonID && id}
                                {...register(
                                  `modules[${moduleIndex}].lessons[${lessonIndex}].attachment_url`,
                                  {
                                    required: {
                                      value:
                                        id && singleEducation ? false : true,
                                      message: "Please insert your lesson URL",
                                    },
                                  }
                                )}
                                error={
                                  errors?.modules?.[moduleIndex]?.lessons?.[
                                    lessonIndex
                                  ]?.attachment_url?.message
                                }
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                      {/* <div className="flex justify-end gap-2 mt-4">
                        <button
                          type="button"
                          className="text-white bg-main text-sm px-4 py-2 rounded-full flex justify-center items-center gap-1 w-full
                          sm:text-base sm:px-8 sm:w-auto"
                          onClick={() => handleAddLesson(moduleIndex)}
                          disabled={id && singleEducation}
                        >
                          <IoIosAddCircleOutline />
                          Add New Lesson
                        </button>
                      </div> */}
                    </div>
                  ))}
                </div>

                {/* {!id && !singleEducation && (
                  <div
                    className="mt-4 text-main text-xl font-paragarphFont font-semibold flex justify-center items-center gap-1 w-full
                    sm:text-base sm:w-auto before:h-0 before:m-2 before:border before:border-main before:flex-1 after:h-0 after:m-2 after:border after:border-main after:flex-1 cursor-pointer hover:text-hoverColor transition ease-in-out duration-300"
                    disabled={id && singleEducation}
                    // onClick={() =>
                    //   appendModule({
                    //     name: "",
                    //     description: "",
                    //     lessons: [
                    //       {
                    //         id: 1,
                    //         name: "",
                    //         type_id: "",
                    //         lesson_details: "",
                    //         attachment_url: "",
                    //       },
                    //     ],
                    //   })
                    // }
                    onClick={appendModuleWithLesson}
                  >
                    <IoIosAddCircleOutline /> Add a New Section
                  </div>
                )} */}
              </div>

              {/* image */}
              <div
                className="flex flex-col justify-end items-start
               w-full lg:w-auto mb-4"
              >
                <Controller
                  name="image"
                  control={control}
                  rules={{
                    required: {
                      value: id && !chapterID && !lessonID ? true : false,
                      message: `Please upload your image.`,
                    },
                  }}
                  render={({ field }) => (
                    <>
                      {!previewImage ? (
                        <FileInput
                          name="image"
                          {...field}
                          label="Course Image"
                          placeholder={<HiOutlinePhoto size={200} />}
                          accept="image/*"
                          disabled={chapterID || lessonID}
                          classNames={{ input: "!rounded-lg w-auto" }}
                          onChange={(file) => {
                            field.onChange(file);
                            if (file) {
                              const imageUrl = URL.createObjectURL(file);
                              setPreviewImage(imageUrl);
                              setImageUrl(imageUrl);
                            } else {
                              setPreviewImage(null);
                              setImageUrl("");
                            }
                          }}
                          // value={previewImage}
                          error={errors?.image?.message}
                        />
                      ) : (
                        <div className="mt-4 h-44">
                          <Image
                            src={previewImage}
                            alt="Image Preview"
                            className="w-full h-100 max-w-xs !rounded-lg"
                          />
                        </div>
                      )}
                    </>
                  )}
                />
              </div>
            </div>

            <div className="flex justify-end gap-2 mt-4">
              <button
                type="submit"
                className="text-white bg-main text-sm px-4 py-2 rounded-full flex justify-center items-center gap-1 w-full
                sm:text-base sm:px-8 sm:w-auto"
              >
                {/* <IoIosAddCircleOutline /> Add New Course */}
                {id && singleEducation ? (
                  <BiSolidEdit />
                ) : (
                  <IoIosAddCircleOutline />
                )}
                {id && !chapterID && !lessonID
                  ? "udpate Course"
                  : chapterID
                  ? "Update Module"
                  : lessonID
                  ? "Update Lesson"
                  : "Add New Course"}
              </button>
            </div>
          </form>
        </div>
      </section>
    </>
  );
}

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../../units/apiConfig";

const getToken = (key) => {
  try {
    return JSON.parse(localStorage.getItem(key)) || "";
  } catch (e) {
    console.error(
      `Error retrieving token from localStorage with key: ${key}`,
      e
    );
    return "";
  }
};

// Define headers with token
const getHeaders = (token) => ({
  Accept: "application/json",
  Authorization: `Bearer ${token}`,
  "Content-Type": "application/json",
});

export const VerficationLoginsApi = createApi({
  reducerPath: "VerficationLoginsApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),

  tagTypes: ["Auth"],
  endpoints: (builder) => ({
    verifyEmail: builder.mutation({
      query: (params) => ({
        url: `/verify/email`,
        method: "POST",
        headers: getHeaders(getToken("verifiy-token")),
        body: params,
      }),
      invalidatesTags: ["Auth"],
    }),
    resetVerifyEmail: builder.mutation({
      query: () => ({
        url: `/verify/resend-email`,
        method: "POST",
        headers: getHeaders(getToken("verifiy-token")),
      }),
      invalidatesTags: ["Auth"],
    }),
    resetPasswordCode: builder.mutation({
      query: () => ({
        url: `/password/resend-code`,
        method: "POST",
        headers: getHeaders(getToken("password_code_token")),
      }),
      invalidatesTags: ["Auth"],
    }),
    confirmCode: builder.mutation({
      query: (params) => ({
        url: `/password/confirm-code`,
        method: "POST",
        headers: getHeaders(getToken("password_code_token")),
        body: params,
      }),
      invalidatesTags: ["Auth"],
    }),
    resetPassword: builder.mutation({
      query: (params) => ({
        url: `/password/reset`,
        method: "POST",
        headers: getHeaders(getToken("password_reset_token")),
        body: params,
      }),
      invalidatesTags: ["Auth"],
    }),
  }),
});

export const {
  useVerifyEmailMutation,
  useResetVerifyEmailMutation,
  useResetPasswordCodeMutation,
  useConfirmCodeMutation,
  useResetPasswordMutation,
} = VerficationLoginsApi;

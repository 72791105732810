import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "../Auth/ConfigApi";
export const SpecialitiesApi = createApi({
  reducerPath: "Speciality",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Speciality"],

  endpoints: (builder) => ({
    getSpecialities: builder.query({
      query: () => ({
        method: "GET",
        url: `/specialities`,
      }),
      providesTags: ["Speciality"],
    }),
  }),
});

export const { useGetSpecialitiesQuery } = SpecialitiesApi;

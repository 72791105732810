import React from "react";

    const Loading = ({ isLoading }) => {
    if (!isLoading) return null;

    return (
    <div className="loader-container flex justify-center items-center h-screen w-full">
        <div
        className="loader border-solid border-8 border-zinc-100 
        border-t-8 border-t-main w-12 h-12 rounded-full animate-spin"
        ></div>
    </div>
    );
};

export default Loading;

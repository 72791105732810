import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "../Auth/ConfigApi";
export const CompaniesApi = createApi({
  reducerPath: "Companies",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Company"],

  endpoints: (builder) => ({
    getCompanies: builder.query({
      query: () => ({
        url: `/company-types`,
      }),
      providesTags: ["Company"],
    }),
  }),
});

export const { useGetCompaniesQuery } = CompaniesApi;
